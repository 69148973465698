<template>
  <div v-if="display === true">
    <van-divider
      dashed
      :style="{
        color: '#00cc99',
        borderColor: '#00cc99',
        margin: '10px 0px',
      }"
      >婚姻 · 生活</van-divider
    >
    <van-row class="item">
      <van-col span="12" class="title">最快结婚时间：</van-col>
      <van-col span="12" class="desc">{{ mlife.marry }}</van-col>
    </van-row>
    <van-row class="item">
      <van-col span="12" class="title">婚姻经历：</van-col>
      <van-col span="12" class="desc">{{ mlife.experience }}</van-col>
    </van-row>
    <van-row class="item">
      <van-col span="12" class="title">是否有孩子：</van-col>
      <van-col span="12" class="desc">{{ mlife.child }}</van-col>
    </van-row>
    <van-row class="item">
      <van-col span="12" class="title">赡养老人：</van-col>
      <van-col span="12" class="desc">{{ mlife.support }}</van-col>
    </van-row>
    <van-row class="item">
      <van-col span="12" class="title">居住方式：</van-col>
      <van-col span="12" class="desc">{{ mlife.live }}</van-col>
    </van-row>
    <van-row class="item">
      <van-col span="12" class="title">生育计划：</van-col>
      <van-col span="12" class="desc">{{ mlife.childbirth }}</van-col>
    </van-row>
  </div>
</template>
<script>
import { Divider } from 'vant'
export default {
  components: {
    [Divider.name]: Divider
  },
  data () {
    return {
      display: false,
      mlife: {
        marry: '',
        child: '',
        live: '',
        support: '',
        childbirth: '',
        experience: ''
      }
    }
  },
  mounted: function () {
  },
  methods: {
    async retrieveMLife (personCode) {
      var pd = { personCode: personCode, group: 'MLF', type: 'DTL' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/retrievePersonAttrGroupList', this.$qs.stringify(pd))
      if (res.status === '200') {
        res.data.forEach(element => {
          var obj = JSON.parse(element.content)
          if (element.key === 'PSN_MLF_MARRY') {
            this.mlife.marry = obj.name
            this.display = true
          } else if (element.key === 'PSN_MLF_LIVE') {
            this.mlife.live = obj.name
            this.display = true
          } else if (element.key === 'PSN_MLF_SUPPORT') {
            this.mlife.support = obj.name
            this.display = true
          } else if (element.key === 'PSN_MLF_CHILD') {
            this.mlife.child = obj.name
            this.display = true
          } else if (element.key === 'PSN_MLF_CHILDBIRTH') {
            this.mlife.childbirth = obj.name
            this.display = true
          } else if (element.key === 'PSN_MLF_EXPERIENCE') {
            this.mlife.experience = obj.name
            this.display = true
          }
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.item {
  border-bottom: 1px dashed #eee;
  padding: 5px 0px;
  .title {
    text-align: right;
  }
  .desc {
    text-align: left;
  }
}
</style>
