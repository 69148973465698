<template>
  <div v-if="familyList.length > 0">
    <van-divider
      dashed
      :style="{
        color: '#00cc99',
        borderColor: '#00cc99',
        margin: '10px 0px',
      }"
      >家庭成员</van-divider
    >
    <van-row class="item" v-for="family in familyList" :key="family.seq">
      <van-col span="24"
        >{{ family.name }}
        <van-tag :color="COLOR">{{ family.relation }}</van-tag> ({{
          family.unitName
        }})</van-col
      >
    </van-row>
  </div>
</template>
<script>
import { Divider, Tag } from 'vant'
export default {
  components: {
    [Divider.name]: Divider,
    [Tag.name]: Tag
  },
  data () {
    return {
      familyList: []
    }
  },
  mounted: function () {
  },
  methods: {
    async retrieveFamily (personCode) {
      var pd = { personCode: personCode, group: 'FMY', type: 'DTL' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/retrievePersonAttrGroupList', this.$qs.stringify(pd))
      if (res.status === '200') {
        res.data.forEach(element => {
          var content = JSON.parse(element.content)
          var family = { seq: element.seq, name: content.name, relation: content.relation, unitName: content.unitName }
          this.familyList.push(family)
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.item {
  border-bottom: 1px dashed #eee;
  padding: 5px 0px;
  .title {
    text-align: right;
  }
  .desc {
    text-align: left;
  }
}
</style>
