<template>
  <div v-if="honorList.length > 0">
    <van-divider
      dashed
      :style="{
        color: '#00cc99',
        borderColor: '#00cc99',
        margin: '10px 0px',
      }"
      >个人荣誉</van-divider
    >
    <van-row class="item" v-for="honor in honorList" :key="honor.seq">
      <van-col span="24">{{ honor.name }} 【{{ honor.issueDate }}】</van-col>
    </van-row>
  </div>
</template>
<script>
import { Divider, Tag } from 'vant'
export default {
  components: {
    [Divider.name]: Divider,
    [Tag.name]: Tag
  },
  data () {
    return {
      honorList: []
    }
  },
  mounted: function () {
  },
  methods: {
    async retrieveHonor (personCode) {
      var pd = { personCode: personCode, group: 'HNR', type: 'DTL' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/retrievePersonAttrGroupList', this.$qs.stringify(pd))
      if (res.status === '200') {
        res.data.forEach(element => {
          var content = JSON.parse(element.content)
          var honor = { seq: element.seq, name: content.name, issueDate: content.issueDate }
          this.honorList.push(honor)
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.item {
  border-bottom: 1px dashed #eee;
  padding: 5px 0px;
  .title {
    text-align: right;
  }
  .desc {
    text-align: left;
  }
}
</style>
