<template>
  <div v-if="houseList.length > 0">
    <van-divider
      dashed
      :style="{
        color: '#00cc99',
        borderColor: '#00cc99',
        margin: '10px 0px',
      }"
      >房产信息</van-divider
    >
    <van-row class="item" v-for="house in houseList" :key="house.seq">
      <van-col span="24"
        >{{ house.address }} - {{ house.area }}平米 -
        {{ house.worth }}万</van-col
      >
    </van-row>
  </div>
</template>
<script>
import { Divider } from 'vant'
export default {
  components: {
    [Divider.name]: Divider
  },
  data () {
    return {
      houseList: []
    }
  },
  mounted: function () {
  },
  methods: {
    async retrieveHouse (personCode) {
      var pd = { personCode: personCode, group: 'HOS', type: 'DTL' }
      this.houseList = []
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/retrievePersonAttrGroupList', this.$qs.stringify(pd))
      if (res.status === '200') {
        res.data.forEach(element => {
          var content = JSON.parse(element.content)
          var house = { seq: element.seq, address: content.address, regionCode: content.regionCode, area: content.area, worth: content.worth }
          this.houseList.push(house)
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.item {
  border-bottom: 1px dashed #eee;
  padding: 5px 0px;
  .title {
    text-align: right;
  }
  .desc {
    text-align: left;
  }
}
</style>
