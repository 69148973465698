<template>
  <div class="page">
    <Navbar title="个人资料-预览" type="SMP" :callback="back" />
    <van-row class="base">
      <van-col span="6" class="photo"
        ><van-image width="63" height="83" :src="person.photoPath"></van-image>
      </van-col>
      <van-col span="18">
        <van-row>
          <van-col span="12" class="item">姓名：{{ person.name }}</van-col>
          <van-col span="12" class="item">年龄：{{ person.age }}岁</van-col>
        </van-row>
        <van-row>
          <van-col span="12" class="item"
            >性别：{{ person.genderName }}</van-col
          >
          <van-col span="12" class="item"
            >民族：{{ person.nationName }}</van-col
          >
        </van-row>
        <van-row>
          <van-col span="24" class="item"
            >身份证号：{{ person.identityNumber }}</van-col
          >
        </van-row>
        <van-row>
          <van-col span="12" class="item"
            >身份证照片-正：<van-tag
              :color="COLOR_M"
              v-if="person.idtfCode !== undefined && person.idtfCode !== ''"
              @click="imageView(person.idtfCode)"
              >查看</van-tag
            ><span v-else>未上传</span></van-col
          >
          <van-col span="12" class="item"
            >身份证照片-反：<van-tag
              :color="COLOR_M"
              @click="imageView(person.idtbCode)"
              >查看</van-tag
            ></van-col
          >
        </van-row>
      </van-col>
    </van-row>
    <van-tabs v-model="active" border="true" :color="COLOR_M">
      <van-tab title="个人资料" name="detail">
        <DTLBaseView ref="dtlBase" />
        <DTLSkillView ref="dtlSkill" />
        <DTLCertView ref="dtlCert" />
        <DTLEduView ref="dtlEdu" />
        <DTLWorkView ref="dtlWork" />
        <DTLHonorView ref="dtlHonor" />
        <DTLFamilyView ref="dtlFamily" />
        <DTLProjectView ref="dtlProject" />
        <DTLMLifeView ref="dtlMLife" />
        <DTLAssetView ref="dtlAsset" />
        <DTLHouseView ref="dtlHouse" />
        <DTLCarView ref="dtlCar" />
        <DTLHobbyView ref="dtlHobby" />
        <DTLHabitView ref="dtlHabit" />
      </van-tab>
      <van-tab title="个人需求" name="demand">内容 2</van-tab>
    </van-tabs>
    <div class="operate">
      <!-- <van-row class="business">
        <van-col span="24">
          <van-field name="radio" label="业务类型" label-width="60px">
            <template #input>
              <van-radio-group
                v-model="business"
                direction="horizontal"
                :checked-color="COLOR_M"
              >
                <van-radio name="RCT">工作</van-radio>
                <van-radio name="TRA">证书</van-radio>
                <van-radio name="ERO">上学</van-radio>
                <van-radio name="LOV">婚恋</van-radio>
              </van-radio-group>
            </template></van-field
          >
        </van-col>
      </van-row> -->
      <van-row>
        <van-col span="24">
          <!-- <van-button
            class="btn"
            icon="share-o"
            :color="COLOR"
            size="small"
            @click="shareShow = true"
            >立即分享</van-button
          > -->
          <van-button
            class="btn"
            icon="down"
            :color="COLOR_M"
            size="small"
            @click="downloadRecruitWord()"
            >下载工作简历</van-button
          >&nbsp;
          <van-button
            class="btn"
            icon="down"
            :color="COLOR_S1"
            size="small"
            @click="downloadTrainFile()"
            >下载证书资料</van-button
          >
        </van-col>
      </van-row>
    </div>
    <van-popup
      :show="shareShow"
      @click-overlay="shareShow = false"
      @click="shareShow = false"
      style="background-color: transparent; width: 100%; height: 100%"
    >
      <img
        class="shareImg"
        :src="require('../../../../assets/images/online/share-top.png')"
      />
    </van-popup>
    <Loading :show="loadingShow" />
    <Share ref="share" />
  </div>
</template>
<script>
import Navbar from '../../common/Navbar.vue'
import Share from '../../common/Share.vue'
import Loading from '../../common/Loading.vue'
import DTLMLifeView from './DTLMLifeView.vue'
import DTLBaseView from './DTLBaseView.vue'
import DTLSkillView from './DTLSkillView.vue'
import DTLCertView from './DTLCertView.vue'
import DTLEduView from './DTLEduView.vue'
import DTLWorkView from './DTLWorkView.vue'
import DTLHonorView from './DTLHonorView.vue'
import DTLFamilyView from './DTLFamilyView.vue'
import DTLProjectView from './DTLProjectView.vue'
import DTLAssetView from './DTLAssetView.vue'
import DTLHouseView from './DTLHouseView.vue'
import DTLCarView from './DTLCarView.vue'
import DTLHobbyView from './DTLHobbyView.vue'
import DTLHabitView from './DTLHabitView.vue'
import { Tag, Collapse, CollapseItem, Image, Popup, Tabs, Tab, RadioGroup, Radio, Field } from 'vant'
export default {
  components: {
    Share: Share,
    Navbar: Navbar,
    Loading: Loading,
    DTLMLifeView: DTLMLifeView,
    DTLBaseView: DTLBaseView,
    DTLSkillView: DTLSkillView,
    DTLCertView: DTLCertView,
    DTLEduView: DTLEduView,
    DTLWorkView: DTLWorkView,
    DTLHonorView: DTLHonorView,
    DTLFamilyView: DTLFamilyView,
    DTLProjectView: DTLProjectView,
    DTLAssetView: DTLAssetView,
    DTLHouseView: DTLHouseView,
    DTLCarView: DTLCarView,
    DTLHobbyView: DTLHobbyView,
    DTLHabitView: DTLHabitView,
    [Tag.name]: Tag,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Image.name]: Image,
    [Popup.name]: Popup,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Field.name]: Field
  },
  data () {
    return {
      code: '',
      shareShow: false,
      loadingShow: false,
      business: 'RCT',
      active: 'detail',
      loginState: 'Y',
      personCode: '',
      workActive: '1',
      projectActive: '',
      person: {},
      personBase: {},
      personSkills: [],
      personCerts: [],
      personProjects: [],
      personHonors: [],
      personEdus: [],
      personWorks: [],
      personFamilys: []
    }
  },
  mounted () {
    var query = this.$route.query
    this.personCode = query.personCode
    var token = window.sessionStorage.getItem(this.SESSION_TOKEN)
    if (query.code !== undefined && token === null) {
      this.code = query.code
      this.login()
    } else if (token === null) {
      window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxa8145a74e5f2b39a&redirect_uri=https%3A%2F%2Fmoc.utopanet.com%2Fperson%2FresumeView%3FpersonCode%3D' + this.personCode + '&response_type=code&scope=snsapi_base&state=123#wechat_redirect'
      this.loginState = 'Y'
    } else {
      this.loginState = 'Y'
      this.init()
    }
  },
  methods: {
    async login () {
      var pd = { code: this.code, source: 'WPP' }
      var { data: res } = await this.$http.post(this.BMS_URL + '/member/user/bindLogin', this.$qs.stringify(pd))
      if (res.status === '200') {
        window.sessionStorage.setItem(this.SESSION_TOKEN, res.data.token)
        window.sessionStorage.setItem(this.SESSION_USER, res.data.userCode)
        this.loginState = 'Y'
        window.sessionStorage.setItem(this.SESSION_SERIAL, res.data.openid)
        this.init()
      } else if (res.code === '4002') {
        window.sessionStorage.setItem(this.SESSION_SERIAL, res.data.openid)
        this.$router.push({ path: '/mde/bind' })
      }
    },
    init () {
      this.retrievePerson()
    },
    initShare () {
      var title = '个人简历 - ' + this.person.name
      var desc = '点击进入查看简历明细'
      var link = 'https://moc.utopanet.com/person/resumeView?personCode=' + this.personCode
      var logo = 'https://moc.utopanet.com/static/logo.png'
      this.$refs.share.init(title, desc, link, logo)
    },
    async retrievePerson () {
      var pd = { code: this.personCode }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/retrievePerson', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.person = res.data
        this.$refs.dtlMLife.retrieveMLife(this.personCode)
        this.$refs.dtlBase.retrieveBase(this.personCode)
        this.$refs.dtlSkill.retrieveSkill(this.personCode)
        this.$refs.dtlCert.retrieveCert(this.personCode)
        this.$refs.dtlEdu.retrieveEdu(this.personCode)
        this.$refs.dtlWork.retrieveWork(this.personCode)
        this.$refs.dtlHonor.retrieveHonor(this.personCode)
        this.$refs.dtlProject.retrieveProject(this.personCode)
        this.$refs.dtlFamily.retrieveFamily(this.personCode)
        this.$refs.dtlAsset.retrieveAsset(this.personCode)
        this.$refs.dtlHouse.retrieveHouse(this.personCode)
        this.$refs.dtlCar.retrieveCar(this.personCode)
        this.$refs.dtlHobby.retrieveHobby(this.personCode)
        this.$refs.dtlHabit.retrieveHabit(this.personCode)
      }
    },
    async downloadRecruitWord () {
      this.loadingShow = true
      var pd = { type: 'resume', personCode: this.personCode }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/exportPersonWord', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.loadingShow = false
        window.location.href = res.data.downloadUrl
      }
    },
    async downloadTrainFile () {
      this.loadingShow = true
      var pd = { personCode: this.personCode, modules: 'PHOTO,IDENTITY,GRADCERT,DEGREECERT,SVR,CERT,' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/exportPersonFile', this.$qs.stringify(pd))
      if (res.status === '200') {
        window.location.href = res.data.downloadUrl
      }
      this.loadingShow = false
    },
    imageView (code) {
      this.$router.push({ path: '/mde/storage/imageView', query: { code: code } })
    },
    back () {
      var url = window.sessionStorage.getItem(window.const.global.PEOPLE_PERSON_VIEW_BACK)
      window.location.href = url
    }
  }
}
</script>
<style lang="less" scoped>
.page {
  padding-bottom: 70px;
}
.title {
  height: 25px;
  line-height: 25px;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  padding-left: 10px;
  color: #fff;
  background: -webkit-gradient(linear, 0 0, 100% 0, from(#00cc99), to(#fff));
}
.hr {
  border-top: 1px solid #eee;
}
.photo {
  text-align: center;
}
.base {
  text-align: left;
  padding: 15px 0px;
  .item {
    height: 20px;
    line-height: 20px;
  }
}
.operate {
  padding: 5px 0px;
  background-color: #fff;
  height: 50px;
  position: fixed;
  width: 100%;
  display: flex;
  flex-flow: column;
  text-align: center;
  justify-content: center;
  bottom: 0px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px #bbb;
  .business {
    border-bottom: 1px solid #eee;
    margin-bottom: 10px;
  }
}
.shareImg {
  width: 90%;
}
</style>
