<template>
  <div v-if="display === true">
    <van-divider
      dashed
      :style="{
        color: '#00cc99',
        borderColor: '#00cc99',
        margin: '10px 0px',
      }"
      >收入资产</van-divider
    >
    <van-row class="item">
      <van-col span="12" class="title">个人月收入：</van-col>
      <van-col span="12" class="desc">{{ asset.selfMIncome }}元</van-col>
    </van-row>
    <van-row class="item">
      <van-col span="12" class="title">个人年收入：</van-col>
      <van-col span="12" class="desc">{{ asset.selfYIncome }}万元</van-col>
    </van-row>
    <van-row class="item">
      <van-col span="12" class="title">个人存款：</van-col>
      <van-col span="12" class="desc">{{ asset.selfDeposit }}万元</van-col>
    </van-row>
    <van-row class="item">
      <van-col span="12" class="title">家庭月收入：</van-col>
      <van-col span="12" class="desc">{{ asset.homeMIncome }}万元</van-col>
    </van-row>
    <van-row class="item">
      <van-col span="12" class="title">家庭年收入：</van-col>
      <van-col span="12" class="desc">{{ asset.homeYIncome }}万元</van-col>
    </van-row>
    <van-row class="item">
      <van-col span="12" class="title">家庭存款：</van-col>
      <van-col span="12" class="desc">{{ asset.homeDeposit }}万元</van-col>
    </van-row>
  </div>
</template>
<script>
import { Divider } from 'vant'
export default {
  components: {
    [Divider.name]: Divider
  },
  data () {
    return {
      display: false,
      asset: { selfMIncome: 0, selfYIncome: 0, selfDeposit: 0, homeMIncome: 0, homeYIncome: 0, homeDeposit: 0 }
    }
  },
  mounted: function () {
  },
  methods: {
    async retrieveAsset (personCode) {
      var pd = { personCode: personCode, group: 'AST', type: 'DTL' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/retrievePersonAttrGroupList', this.$qs.stringify(pd))
      if (res.status === '200') {
        res.data.forEach(element => {
          var obj = JSON.parse(element.content)
          if (element.key === 'PSN_AST_SMI') {
            this.asset.selfMIncome = obj.amount
            this.display = true
          } else if (element.key === 'PSN_AST_SYI') {
            this.asset.selfYIncome = obj.amount
            this.display = true
          } else if (element.key === 'PSN_AST_SD') {
            this.asset.selfDeposit = obj.amount
            this.display = true
          } else if (element.key === 'PSN_AST_HMI') {
            this.asset.homeMIncome = obj.amount
            this.display = true
          } else if (element.key === 'PSN_AST_HYI') {
            this.asset.homeYIncome = obj.amount
            this.display = true
          } else if (element.key === 'PSN_AST_HD') {
            this.asset.homeDeposit = obj.amount
            this.display = true
          }
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.item {
  border-bottom: 1px dashed #eee;
  padding: 5px 0px;
  .title {
    text-align: right;
  }
  .desc {
    text-align: left;
  }
}
</style>
