<template>
  <div v-if="display === true">
    <van-divider
      dashed
      :style="{
        color: '#00cc99',
        borderColor: '#00cc99',
        margin: '10px 0px',
      }"
      >兴趣爱好</van-divider
    >
    <van-row class="item">
      <van-col span="12" class="title">喜欢的食物：</van-col>
      <van-col span="12" class="desc">{{ hobby.food }}</van-col>
    </van-row>
    <van-row class="item">
      <van-col span="12" class="title">喜欢的书籍：</van-col>
      <van-col span="12" class="desc">{{ hobby.book }}</van-col>
    </van-row>
    <van-row class="item">
      <van-col span="12" class="title">喜欢的运动：</van-col>
      <van-col span="12" class="desc">{{ hobby.sport }}</van-col>
    </van-row>
    <van-row class="item">
      <van-col span="12" class="title">喜欢的电影：</van-col>
      <van-col span="12" class="desc">{{ hobby.movie }}</van-col>
    </van-row>
    <van-row class="item">
      <van-col span="12" class="title">喜欢的歌曲：</van-col>
      <van-col span="12" class="desc">{{ hobby.song }}</van-col>
    </van-row>
    <van-row class="item">
      <van-col span="12" class="title">喜欢的名人：</van-col>
      <van-col span="12" class="desc">{{ hobby.celebrity }}</van-col>
    </van-row>
  </div>
</template>
<script>
import { Divider } from 'vant'
export default {
  components: {
    [Divider.name]: Divider
  },
  data () {
    return {
      display: false,
      hobby: { food: '', book: '', sport: '', movie: '', song: '', celebrity: '' }
    }
  },
  mounted: function () {
  },
  methods: {
    async retrieveHobby (personCode) {
      var pd = { personCode: personCode, group: 'HBY', type: 'DTL' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/retrievePersonAttrGroupList', this.$qs.stringify(pd))
      if (res.status === '200') {
        res.data.forEach(element => {
          var obj = JSON.parse(element.content)
          if (element.key === 'PSN_HBY_FOOD') {
            this.hobby.food = obj.name
            this.display = true
          } else if (element.key === 'PSN_HBY_BOOK') {
            this.hobby.book = obj.name
            this.display = true
          } else if (element.key === 'PSN_HBY_SPORT') {
            this.hobby.sport = obj.name
            this.display = true
          } else if (element.key === 'PSN_HBY_MOVIE') {
            this.hobby.movie = obj.name
            this.display = true
          } else if (element.key === 'PSN_HBY_SONG') {
            this.hobby.song = obj.name
            this.display = true
          } else if (element.key === 'PSN_HBY_CELEBRITY') {
            this.hobby.celebrity = obj.name
            this.display = true
          }
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.item {
  border-bottom: 1px dashed #eee;
  padding: 5px 0px;
  .title {
    text-align: right;
  }
  .desc {
    text-align: left;
  }
}
</style>
