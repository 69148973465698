<template>
  <div v-if="display === true">
    <van-divider
      dashed
      :style="{
        color: '#00cc99',
        borderColor: '#00cc99',
        margin: '10px 0px',
      }"
      >生活习惯</van-divider
    >
    <van-row class="item">
      <van-col span="12" class="title">吸烟：</van-col>
      <van-col span="12" class="desc">{{ habit.smoke }}</van-col>
    </van-row>
    <van-row class="item">
      <van-col span="12" class="title">喝酒：</van-col>
      <van-col span="12" class="desc">{{ habit.drink }}</van-col>
    </van-row>
    <van-row class="item">
      <van-col span="12" class="title">运动：</van-col>
      <van-col span="12" class="desc">{{ habit.sport }}</van-col>
    </van-row>
    <van-row class="item">
      <van-col span="12" class="title">旅行：</van-col>
      <van-col span="12" class="desc">{{ habit.travel }}</van-col>
    </van-row>
    <van-row class="item">
      <van-col span="12" class="title">聚会：</van-col>
      <van-col span="12" class="desc">{{ habit.party }}</van-col>
    </van-row>
  </div>
</template>
<script>
import { Divider } from 'vant'
export default {
  components: {
    [Divider.name]: Divider
  },
  data () {
    return {
      display: false,
      habit: { smoke: '', drink: '', sport: '', travel: '', party: '' }
    }
  },
  mounted: function () {
  },
  methods: {
    async retrieveHabit (personCode) {
      var pd = { personCode: personCode, group: 'HBT', type: 'DTL' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/retrievePersonAttrGroupList', this.$qs.stringify(pd))
      if (res.status === '200') {
        res.data.forEach(element => {
          var obj = JSON.parse(element.content)
          if (element.key === 'PSN_HBT_SMOKE') {
            this.habit.smoke = obj.name
            this.display = true
          } else if (element.key === 'PSN_HBT_DRINK') {
            this.habit.drink = obj.name
            this.display = true
          } else if (element.key === 'PSN_HBT_TRAVEL') {
            this.habit.travel = obj.name
            this.display = true
          } else if (element.key === 'PSN_HBT_PARTY') {
            this.habit.party = obj.name
            this.display = true
          } else if (element.key === 'PSN_HBT_SPORT') {
            this.habit.sport = obj.name
            this.display = true
          }
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.item {
  border-bottom: 1px dashed #eee;
  padding: 5px 0px;
  .title {
    text-align: right;
  }
  .desc {
    text-align: left;
  }
}
</style>
